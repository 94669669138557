import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { overrideConsole } from 'features/lib';
import { setupWindowErrorHandler } from 'features/errors/sentry';

setupWindowErrorHandler();
overrideConsole();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Bowser = require('bowser');

const browser = Bowser.getParser(window.navigator.userAgent);
const isInvalidBrowser = browser.satisfies({
  msie: '<15',
  ie: '<15',
  'internet explorer': '<15',
  // chrome: '>30',
});

if (isInvalidBrowser) {
  window.location.href = '/browser.html';
  throw new Error('Not supported Browser!');
}

import('./root').catch((e) => {
  window.location.href = '/error.html';
  console.error('An error occurred!', e);
});
