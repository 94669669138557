import { franc } from 'franc';
import { customAlphabet } from 'nanoid';
import { isPlainObject } from 'lodash';

import type { Text } from 'features/types';

const TEST_STORAGE_KEY = '__test';

// 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
export const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 18); // ~2 million years
export const nanoid14 = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 14); // ~1 thousand years

export const isTouchDevice = () =>
  'ontouchstart' in window || // works on most browsers
  Boolean(navigator.maxTouchPoints); // works on IE10/11 and Surface

export const consoleLog = (...values: any[]) => {
  process.env.NODE_ENV === 'development' && console.log(...values);
};

export const overrideConsole = () => {
  const console = ((oldConsole) => ({
    ...oldConsole,
    log: (...values: any[]) => {
      process.env.NODE_ENV === 'development' && oldConsole.log(...values);
    },
    info: (...values: any[]) => {
      process.env.NODE_ENV === 'development' && oldConsole.info(...values);
    },
    warn: (...values: any[]) => {
      process.env.NODE_ENV === 'development' && oldConsole.warn(...values);
    },
    error: (...values: any[]) => {
      process.env.NODE_ENV === 'development' && oldConsole.error(...values);
    },
    debug: (...values: any[]) => {
      process.env.NODE_ENV === 'development' && oldConsole.debug(...values);
    },
  }))(window.console);

  window.console = console;
};

export const detectTextLang = (text: Text) => {
  // const string = text.pages.map(page => page.text.map(par => par.text).join(' ')).join(' ');
  // const lang = detectStringLang(string);
  if (!text || !text.pages) {
    return null;
  }
  let string = '';
  const pages = text.pages.filter((p) => p.text && p.text.length);
  for (let i = 0; i < pages.length; i += 1) {
    const page = pages[i];
    if (string.length > 1000) {
      break;
    }

    for (let j = 0; j < page.text.length; j += 1) {
      const para = page.text[j];
      if (string.length > 1000) {
        break;
      }

      string += para.text ? ` ${para.text}` : '';
    }
  }
  const lang = franc(string.trim());
  // consoleLog('detectTextLang', text, string, lang);
  return lang;
};

// export const resolveAppliedStyles = (css) => Object
//   .keys(css)
//   .map((key) => `${key}${isPlainObject(css[key]) ? ` { ${resolveAppliedStyles(css[key])} }` : `: ${toString(css[key])};`}`)
//   .join('\n');

export const hasStorage = (name: string) => {
  try {
    const storage = (window as any)[name];
    storage.setItem(TEST_STORAGE_KEY, '1');
    storage.removeItem(TEST_STORAGE_KEY);
    return true;
  } catch (e) {
    return false;
  }
};

export const safeScrollTo = (el: any, ...args: any) => {
  if (!el) {
    return;
  }

  if (el.scrollTo) {
    el.scrollTo(...args);
  } else if (isPlainObject(args[0])) {
    // scrollTo({ top: 0, behavior: 'smooth' })
    if (args[0].top !== undefined) {
      el.scrollTop = args[0].top;
    }
  } else {
    if (args[1] !== undefined) {
      // scrollTo(0, 0)
      el.scrollTop = args[1];
    }
  }
};
