import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://d46401bd5429495b872a0b2d5cdc6e02@sentry.io/1327800',
  enabled: process.env.NODE_ENV === 'production',
  debug: false,
});

export const setupWindowErrorHandler = () => {
  if (!(window && window.onerror)) {
    return;
  }

  window.onerror = (message: any, file: any, line: any, column: any, errorObject: any) => {
    const stack = errorObject ? errorObject.stack : null;

    const data = {
      message,
      file,
      line,
      column,
      errorStack: stack,
      error: errorObject,
    };

    Sentry.configureScope((scope) => {
      scope.setExtra('extra', data);
    });

    if (errorObject) {
      Sentry.captureException(errorObject);
    } else {
      Sentry.captureMessage(`window.onerror: ${message}`);
    }

    // the error can still be triggered as usual, we just wanted to know what's happening on the client side
    return false;
  };
};

export default Sentry;
